@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap');
html {
  background-color: #14151a; /* #3249fe */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
body {
  background-color: transparent;
  font-family: 'Space Grotesk', sans-serif;
}
.blackBg {
  background-color: #000000;
}
.yellowText, .yellowFont {
  color: #ffe900;
}

.headLine {
  font-size: 42px;
}
.headLine, .headLineSub {
  text-align: center;
}
.center {
  text-align: center;
}
.cen button {
  margin: auto;
}
.App {
  text-align: center;
  font-family: 'Space Grotesk', sans-serif;
  color: #c4c5cb;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.navbar {
  margin-left: -10px;
  margin-right: -10px;
  border-radius: 10px;
}
.deepShadow {
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.5),
  inset 5px 5px 8px rgba(0,0,0,0.5);
  padding: 10px;
}
.navbar-brand img {
  width: 60px;
  height: 60px;
}
.nav-link {
  color: #8a8f95;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  margin-left: 20px;
  margin-right: 20px;
}
a.nav-link {
  padding-left: 10px;
  padding-right: 10px;
}
.nav-link:hover {
  color: #8a8f95;
  background: #111113;
  border-radius: 5px;
}
.btn-cw {
  border-color: #bfb063;
  color: #bfb063;
}
.btn-cw:hover {
  border-color: #bfb063;
  background-color: #bfb063;
}
.btn-oplock {
  border-color: #f7f7f8;
  border-width: 3px;
  background-color: transparent;
  outline:none !important;
  box-shadow: none !important;
  color: #f7f7f8;
}
.btn-oplock:hover {
  background-color: #f7f7f8;
  color: #181a1e;
}
#feedbackMessage {
	top: 50%;
	left: 50%;
	width: 600px;
	margin-left: -300px;
	position: absolute;
	z-index: 100;
}
#feedbackMessage .alert-box {
	text-align: center;
	padding: 15px;
  border: 1px solid transparent;
  border-radius: 4px;
}
#feedbackMessage .not-loggedin-metamask {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
	display: none;
}
#feedbackMessage .metamask-login {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
	display: none;
}
#logo2 {
  display: none;
}
#logo2 img {
  width: 100px;
  height: 100px;
}
.smileyContainer {
	position: relative;
	width: 50%;
	max-width: 500px;
	margin: auto;
}
.smileyImage {
	display: block;
	width: 100%;
	height: auto;
}
.smileyOverlay {
	position: absolute;
	bottom: 0;
	background: rgb(0, 0, 0);
	background: radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
	width: 100%;
	height: 100%;
	color: white;
	font-size: 20px;
	padding: 20px;
	text-align: center;
}
a.yellow {
	color: #ffe900;
	text-decoration: none;
}
a.yellow:hover {
	color: #ffe900;
	text-decoration: underline;
}
.logoHolder {
	max-width: 160px;
	height: auto;
	margin: auto;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.container-fluid {
  padding: 0px;
  max-width: 100%;
}
.container-fluid .row {
  margin-left: 0px;
  margin-right: 0px;
}
.App-header {
  /* background-color: #282c34; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}
.o08 {
  opacity: 0.8;
}
h3.smallFont {
  font-size: 16px;
}
.smallFont {
  font-size: 14px;
}
.blackBgo {
  background: rgba(0, 0, 0, 0.6);
}
#refMessage {
  font-size: calc(10px + 2vmin);
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #6e2a1c;
  padding: 30px;
  border-radius: 25px;
}

.App-link {
  color: #61dafb;
}
img {
  width: 100%;
  height: auto;
}
.bg {
  /* The image used */
  /* background-image: url("./background.jpg"); */
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.transparentBg {
  background-color: rgba(0, 0, 0, 0.7);
}
.greyBg {
  background-color: #111111;
  border-radius: 10px;
}
.padding20px {
  padding: 20px;
}
.spacer5px {
  height: 5px;
  width: 100%;
}
.spacer20px {
  height: 20px;
  width: 100%;
}
.spacer50px {
  height: 50px;
  width: 100%;
}
input[type="text"] {
  outline: none;
  border: none;
  border-radius: 5px;
}
.ju {
  text-align: justify;
}
.refresh {
  cursor: pointer;
}
.refresh:hover {
  font-weight: bold;
}
.lefted {
  text-align: left;
}
.goldFont {
  color: #bfb063;
}
.whiteFont {
  color: #ffffff;
}
.blackFont {
  color: #000000;
}
.greenFont {
  color: #63bf69;
}
.redFont {
  color: #b73a4e;
}
.whiteBg {
  background-color: #f2f2f2;
}
.bigFont {
  font-size: 24px;
  font-weight: 600;
}
img.statusOkNotok {
  width: 200px;
  height: 200px;
  margin: auto;
}
.fadeIn {
  animation: fadein 500ms;
}
@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.fadeOut {
  animation: fadeout 500ms;
}
@keyframes fadeout {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
#readOnly, #whenConnected, #whenNotConnected, #connect-block, #connectionStatus, #tokenPairNotFound, #tokenPairFound, #tokenBalanceOwner, #inputTokenAmount, #tokenBalanceOwnerNotMatch, #inputLockTime, #startLockButton, #visitCertificate, #extendLockButton, #extendLockTimeButton, #withdrawLockButton {
  display: none;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btnx {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 30px;
  text-align: center;
  line-height: 20px;
  color: #f1f1f1;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  font-family: sans-serif;
  box-sizing: border-box;
  background: linear-gradient(90deg, #bfb063, #c1a30c, #8e780a, #bfb063);
  background-size: 400%;
  border-radius: 10px;
  z-index: 1;
}

.btnx:hover {
  animation: animate 8s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}

.btnx:before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #bfb063, #c1a30c, #8e780a, #bfb063);
  background-size: 400%;
  border-radius: 40px;
  opacity: 0;
  transition: .5s;
}

.btnx:hover:before {
  filter: blur(20px);
  opacity: 1;
  animation: animate 8s linear infinite;
}
.btnxb {
  width: 300px;
  height: 50px;
  line-height: 25px;
  font-size: 20px;
  font-weight: 600;
}
.btnxn {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 30px;
  text-align: center;
  line-height: 20px;
  color: #f1f1f1;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  font-family: sans-serif;
  box-sizing: border-box;
  background: linear-gradient(90deg, #bf6374, #723943, #190c0f, #bf6374);
  background-size: 400%;
  border-radius: 10px;
  z-index: 1;
}

.btnxn:hover {
  animation: animate 8s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}

.btnxn:before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #bf6374, #723943, #190c0f, #bf6374);
  background-size: 400%;
  border-radius: 40px;
  opacity: 0;
  transition: .5s;
}

.btnxn:hover:before {
  filter: blur(20px);
  opacity: 1;
  animation: animate 8s linear infinite;
}

.btnxs {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 30px;
  text-align: center;
  line-height: 20px;
  color: #f1f1f1;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  font-family: sans-serif;
  box-sizing: border-box;
  background: black;
  background-size: 400%;
  border-radius: 10px;
  z-index: 1;
}

.btnxs:hover {
  animation: animate 8s linear infinite;
}

.active-s {
  background: linear-gradient(90deg, #bfb063, #c1a30c, #8e780a, #bfb063);
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}

.btnxs:before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #bfb063, #c1a30c, #8e780a, #bfb063);
  background-size: 400%;
  border-radius: 40px;
  opacity: 0;
  transition: .5s;
}

.btnxs:hover:before {
  filter: blur(20px);
  opacity: 1;
  animation: animate 8s linear infinite;
}
